.formContainer {
    border: 1px solid black;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    max-width: 30%;
    margin: auto;
    padding: 20px;
    margin-bottom: 20px;
}

input {
    margin-bottom: 10px;
}
